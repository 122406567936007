
import {
  defineComponent,
  ref,
  unref,
  toRefs,
  watch
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';
import { RuleItem } from 'async-validator';
import { useDirector, useCreateDirector, useUpdateDirector } from '@/composables/api';
import useFormErrors from '@/utils/validate';
import { IntBoolean, ResponseError } from '@/services/api';
import { PartialDirector } from '@/interfaces/Director';

const DEFAULT_FORM_VALUES: PartialDirector = {
  name: ''
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const directorId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { data, dataUpdatedAt } = useDirector({ directorId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });
    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    const { isLoading: isCreatedLoading, mutate: create } = useCreateDirector();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateDirector();
    const { formErrors, bindFormItemError } = useFormErrors();

    const rules = {
      name: [
        {
          required: true,
          trigger: 'blur'
        }
      ]
    };

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          if (isEdit.value) {
            update({ directorId, data: formValues.value }, {
              onSuccess() {
                ElMessage.success('success!');

                router.push({
                  name: 'list-directors'
                });
              },

              onError(error: ResponseError) {
                ElMessage.error(error.response?.data.message);
                formErrors.value = error.response?.data.errors;
              }
            });

            return;
          }

          create({ data: formValues.value }, {
            onSuccess() {
              ElMessage.success('success!');

              formValues.value = DEFAULT_FORM_VALUES;
              router.push({
                name: 'list-directors'
              });
            },
            onError(error: ResponseError) {
              ElMessage.error(error.response?.data.message);
              formErrors.value = error.response?.data.errors;
            }
          });
        }
      });
    };

    return {
      page,
      formValues,
      formRef,
      rules,
      IntBoolean,
      isCreatedLoading,
      isUpdatedLoading,
      submitForm,
      formErrors,
      bindFormItemError
    };
  }
});
